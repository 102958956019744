import React from "react";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import bwcWhite from "../assets/bwc-white-logo.svg";

const Contact = () => {
  const showModal = () => {
    document.getElementById("form_subs").style.zIndex = 9;
    var inInterval = setInterval(function () {
      document.getElementById("form_subs").style.opacity =
        Number(document.getElementById("form_subs").style.opacity) + 0.02;
      if (document.getElementById("form_subs").style.opacity >= 1)
        clearInterval(inInterval);
    }, 15);
  };
  return (
    <div className="contact" id="contact">
      {window.location.hash === "#contact" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Connect</title>
          <meta
            name="description"
            content="If you are a founder who are ready to step up for the collective good, connect with us by submitting your pitch! Join our journey to build amazing companies."
          />
        </Helmet>
      )}
      <div className="contact-details">
        <img src={bwcWhite} alt="" />
        <div>
          <h3>Are you a Founder?</h3>
          <Link
            to={{
              pathname:
                "https://share.hsforms.com/18nsDM9SLSaSm_BtVNCeTjw5fj7a",
            }}
            target="_blank"
            style={{ width: "100%" }}
          >
            <Button fullWidth variant="outlined">
              PITCH SUBMISSION
            </Button>
          </Link>
        </div>
        <div>
          <h3>Stay updated with us!</h3>
          <Button variant="outlined" onClick={() => showModal()}>
            SUBSCRIBE HERE
          </Button>
        </div>
      </div>
      <div className="contact-details">
        <div className="contact-info">
          <div className="contact-location" style={{ visibility: "hidden" }}>
            <p className="semi-bold">Email</p>
            <p>connect@bwcapital.vc</p>
          </div>
        </div>
        <div className="contact-info">
          <div className="contact-location">
            <p className="semi-bold">Blackwolves Capital Pte.Ltd</p>
          </div>
          <div className="contact-location">
            <p className="semi-bold">SINGAPORE</p>
            <p>111 North Bridge Road</p>
            <p style={{ color: "#939393" }}>
              #17-06 Peninsula Plaza
              <br /> Singapore.
              <br /> 179098
            </p>
          </div>
          <hr
            style={{
              width: "21%",
              color: "#939393",
              alignSelf: "flex-start",
              margin: "2% 0 5% 0",
              border: "0.75px solid #939393",
            }}
          />
          <div className="contact-location">
            <p className="semi-bold">INDONESIA</p>
            <p>Ruko Cordoba Blok F No.23,</p>
            <p style={{ color: "#939393" }}>
              Jl. Marina Raya, Kamal Muara,
              <br /> North Jakarta City.
              <br /> Jakarta 14470
            </p>
          </div>
        </div>
        <div className="contact-info">
          <div className="contact-location">
            <p className="semi-bold">Content</p>
            <p>
              <a href="/#about">About</a>
            </p>
            <p>
              <a href="/#portfolio">Portfolio</a>
            </p>
            <p>
              <a href="/#contact">Contact</a>
            </p>
            <p>
              <a
                href="https://share.hsforms.com/18nsDM9SLSaSm_BtVNCeTjw5fj7a"
                target="_blank"
                style={{
                  textDecoration: "none",
                }}
              >
                Submission
              </a>
            </p>
          </div>
          <div className="contact-location">
            <p className="semi-bold">Social Media</p>
          </div>
        </div>
      </div>
      <p>Black Wolves Capital 2022. All Right Reserved.</p>
    </div>
  );
};

export default Contact;

import React from "react";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <div className="about main-content" id="about">
      {window.location.hash === "#about" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>About Us</title>
          <meta
            name="description"
            content="Black Wolves Capital is an angel investor firm based in Indonesia and Singapore. We focus on helping early-stage companies to grow and reach profitability."
          />
        </Helmet>
      )}
      <h2>ABOUT</h2>
      <h4>
        Black Wolves Capital is an Indonesia-based angel investor firm focusing
        on helping early-stage companies to grow. Our core team consists of
        professionals, entrepreneurs, and leaders who bring combined experiences
        in business development and go to market strategies. We nurture our pack
        to be mercurial, adaptable, and instinctive in building their future
        domination.
      </h4>
    </div>
  );
};

export default About;

import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";
import bwcWhite from "../assets/bwc-white-logo.svg";
import { Dehaze } from "@mui/icons-material";
import { IconButton, Menu, MenuItem } from "@mui/material";

const Navbar = () => {
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar">
      <span style={{ padding: "1.15% 0" }}>
        <a href="/">
          <img src={bwcWhite} alt="" />
        </a>
      </span>
      {window.innerWidth > 600 ? (
        <div>
          <span>
            <a href="/#about">
              <p>ABOUT</p>
            </a>
          </span>
          <span>
            <a href="/#portfolio">
              <p>PORTFOLIO</p>
            </a>
          </span>
          <span>
            <a href="/#contact">
              <p>CONTACT</p>
            </a>
          </span>
          <a
            href="https://share.hsforms.com/18nsDM9SLSaSm_BtVNCeTjw5fj7a"
            target="_blank"
            style={{
              textDecoration: "none",
            }}
          >
            <button
              style={{
                padding: "7.5%",
                fontSize: "14px",
                color: "#292929",
              }}
            >
              SUBMISSION
            </button>
          </a>
        </div>
      ) : (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Dehaze style={{ color: "#FFFFFF" }} />
          </IconButton>
        </div>
      )}
      {window.innerWidth < 600 && (
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          marginThreshold={0}
          PaperProps={{
            style: {
              width: "100%",
              maxWidth: "100%",
              left: 0,
              right: 0,
              background: "#292929",
              marginTop: "1.6vh",
            },
          }}
        >
          <MenuItem
            onClick={() => {
              history.push("/#about");
              handleClose();
            }}
            style={{ width: "100%" }}
          >
            <a href="/#about" style={{ width: "100%", padding: "3.25% 0" }}>
              <p>ABOUT</p>
            </a>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/#portfolio");
              handleClose();
            }}
            style={{ width: "100%" }}
          >
            <a href="/#portfolio" style={{ width: "100%", padding: "3.25% 0" }}>
              <p>PORTFOLIO</p>
            </a>
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.push("/#contact");
              handleClose();
            }}
            style={{ width: "100%" }}
          >
            <a href="/#contact" style={{ width: "100%", padding: "3.25% 0" }}>
              <p>CONTACT</p>
            </a>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
            }}
            style={{ width: "100%" }}
          >
            <a
              href="https://share.hsforms.com/18nsDM9SLSaSm_BtVNCeTjw5fj7a"
              target="_blank"
              style={{
                textDecoration: "none",
                width: "100%",
                padding: "3.25% 0",
              }}
            >
              <p>SUBMISSION</p>
            </a>
          </MenuItem>
        </Menu>
      )}
    </div>
  );
};

export default Navbar;

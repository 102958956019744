import React from "react";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import "./components/style.css";
import "./page/style.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import MainPage from "./page/Main";

const App = () => {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/" exact component={MainPage} />
        </Switch>
      </Router>
    </Provider>
  );
};

export default App;

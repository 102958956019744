import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";

import { Link, useHistory } from "react-router-dom";

import funding1 from "../assets/Funding-01.svg";
import development1 from "../assets/Development-01.svg";
import collab from "../assets/Collaboration-01.svg";

import About from "../components/About";
import Portfolio from "../components/Portfolio";
import Contact from "../components/Contact";

import { Helmet } from "react-helmet";
const Main = () => {
  const history = useHistory();
  const [splashDone, setSplashDone] = useState(false);

  const showModal = () => {
    document.getElementById("form_subs").style.zIndex = 9;
    var inInterval = setInterval(function () {
      document.getElementById("form_subs").style.opacity =
        Number(document.getElementById("form_subs").style.opacity) + 0.02;
      if (document.getElementById("form_subs").style.opacity >= 1)
        clearInterval(inInterval);
    }, 15);
  };
  useEffect(() => {
    setTimeout(() => {
      setSplashDone(true);
      if (
        !sessionStorage.getItem("loaded") &&
        document.getElementById("form_subs").style.opacity == 0
      ) {
        showModal();
      }
    }, 11300);
    setTimeout(() => {
      sessionStorage.setItem("loaded", true);
    }, 15000);
  }, []);

  return (
    <>
      {!splashDone ? (
        <div
          style={{
            minWidth: window.innerWidth > 600 ? "100%" : window.innerWidth,
            maxWidth: window.innerWidth > 600 ? "100%" : window.innerWidth,
            maxHeight: window.innerWidth > 600 ? "100vh" : window.innerHeight,
            minHeight: window.innerWidth > 600 ? "100vh" : window.innerHeight,
            background: "black",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <video autoPlay muted width="100%" draggable="false">
            <source
              src={require("../assets/BWC-Loading-Screen.mp4")}
              type="video/mp4"
            />
          </video>
        </div>
      ) : (
        <div className="landing">
          <Navbar />
          <div className="landing-content" id="">
            {window.location.hash === "" && (
              <Helmet>
                <meta charSet="utf-8" />
                <title>Home</title>
                <meta
                  name="description"
                  content="Join us in our journey to build amazing companies and ecosystem of daring founders. We bring our combined experiences to help early stage startups to grow."
                />
              </Helmet>
            )}
            <h1>
              Join Our Pack of <br /> Amazing Companies
            </h1>
          </div>
          <About />
          <Portfolio />
          <hr
            style={{
              width: "96%",
              margin: "1.5%",
              border: "0.7px solid #C4C4C4",
            }}
          />
          <div
            className="work-together"
            style={{ maxWidth: window.innerWidth }}
          >
            <h2>HOW WE WORK TOGETHER</h2>
            <div>
              <div className="card">
                <img alt="Funding" src={funding1} width="140%" />
                <p className="semi-bold">Funding</p>
                <h6>
                  We hear stories of daring entrepreneurs ready to step up for
                  collective good and build amazing companies. Let us be part of
                  the "once upon a time".
                </h6>
              </div>
              <div className="card">
                <img alt="Development" src={development1} width="140%" />
                <p className="semi-bold">Development</p>
                <h6>
                  In a successful journey, great supports are essential. We
                  focus on adding value to the ventures we partner with,
                  reaching profitability and sustainability.
                </h6>
              </div>
              <div className="card">
                <img alt="Collaboration" src={collab} width="140%" />
                <p className="semi-bold">Collaboration</p>
                <h6>
                  We aim to create an ecosystem of great companies, producing
                  collaborative success that enhance one another. It is going to
                  be a massive cross-over.
                </h6>
              </div>
            </div>
          </div>
          <hr
            style={{
              width: "96%",
              margin: "1.5%",
              border: "0.7px solid #C4C4C4",
            }}
          />
          <Contact />
        </div>
      )}
    </>
  );
};

export default Main;

import React from "react";

import { Carousel } from "react-responsive-carousel";
import { Helmet } from "react-helmet";

import prev from "../assets/prev.svg";
import next from "../assets/next.svg";

import lacak from "../assets/lacak-logo.svg";
import koDanny from "../assets/koDanny.png";
import transporta from "../assets/transporta-logo.svg";
import mbaEmma from "../assets/mbaEmma.png";

const Portfolio = () => {
  return (
    <div className="portfolio main-content" id="portfolio">
      {window.location.hash === "#portfolio" && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>Portfolio</title>
          <meta
            name="description"
            content="Our ecosystem of companies share the same goal which is profitability and stability. Here are the startups that grow along with us in our massive crossover."
          />
        </Helmet>
      )}
      <p>PORTFOLIO</p>
      <div className="ports">
        <Carousel
          showArrows={true}
          autoPlay
          infiniteLoop
          swipeable
          showStatus={false}
          showThumbs={false}
          showIndicators={false}
          renderArrowPrev={(onClickHandler, hasPrev, label) =>
            hasPrev && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carou-btns"
              >
                <img src={prev} alt="" width="100%" />
              </button>
            )
          }
          renderArrowNext={(onClickHandler, hasNext, label) =>
            hasNext && (
              <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="carou-btns-next"
              >
                <img src={next} alt="" width="100%" />
              </button>
            )
          }
          width="100%"
        >
          <div className="portfolio-detail">
            <div className="porto-info">
              <div>
                <img src={lacak} alt="lacak.io" className="logo1" />
                <p className="carou-text">
                  BWC has helped us to expand our horizon and led us into an
                  <br />
                  exponential profitability.
                </p>
                <h5>- Danny Jiang, Co-Founder & COO Lacak.io</h5>
              </div>
              <img src={koDanny} alt="Danny" className="human" />
            </div>
          </div>
          <div className="portfolio-detail">
            <div className="porto-info">
              <div>
                <img src={transporta} alt="lacak.io" className="logo2" />
                <p className="carou-text">
                  As a newly launched startup, Transporta is able to get all the
                  <br />
                  supports to scale up faster.
                </p>
                <h5>- Emma Hartono, Co-Founder & COO Transporta.id</h5>
              </div>
              <img src={mbaEmma} alt="Emma" className="human" />
            </div>
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Portfolio;
